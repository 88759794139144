import React from 'react';
import { Container, Col, Button,  } from "reactstrap";

import '../../onboarding.css';
import { colours } from "../../config";
import BlueBackground from "../shared/BlueBackground";
import ButterflyBulletPointRow from "../shared/ButterflyBulletPointRow";
import logo from "../../static/images/ccc-logo-transparent.png";
import certificate from "../../static/images/gift-certificate-example.png";

const GroupCoaching = () => {

  return (
    <React.Fragment>
      <BlueBackground />
      <Container
        fluid
        style={{backgroundColor: colours.prussianBlue, color: colours.deepChampagne, minHeight: "100%"}}
      >

        <Col xs={12} className="d-flex justify-content-center">
          <img src={logo} style={{width: '64px', marginTop: '20px'}} alt="Cotswold Coaching Clinic Logo" />
        </Col>
        <Col xs={12} className="d-flex justify-content-center">
          <div>
            <h1 className="w-100 text-center my-4" style={{color: colours.deepChampagne, fontSize:'4em'}}>
              Looking for a unique Christmas gift?
            </h1>
          </div>
        </Col>
        <Col xs={12} className="d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <h2 className="w-100 text-center my-2" style={{color: '#ffffff'}}>
              Gift the power of transformation!
            </h2>
          </div>
        </Col>
        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <p>
              It's that wonderful time of the year when we're considering what our friends and loved ones might want for Christmas.
            </p>
            <p>
              Increasingly people are opting for experiences rather than 'things' - this is great for the environment and good for the soul.
            </p>
            <p>
              If you're after something unique, personalised, holistic and impactful, have a look at Cotswold Coaching Clinic's 'Revive' coaching package.
              It's a refreshing way to kick off 2025 and you might even decide you want one for yourself!
            </p>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <h2 className="w-100 text-center my-2" style={{color: '#ffffff'}}>
              What is coaching?
            </h2>
          </div>
        </Col>
        <Col xs={12} className="p-2 d-flex justify-content-center">
          <div style={{
              position: 'relative',
              width: '100%',
              maxWidth: '1024px',
              aspectRatio: '16 / 9'
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
              src="https://www.youtube.com/embed/7PQkBTrKqRw"
              title="A Christmas Fireside Chat - Part 1" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <h2 className="w-100 text-center my-2" style={{color: '#ffffff'}}>
              What are the benefits of coaching?
            </h2>
          </div>
        </Col>
        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <ButterflyBulletPointRow gold label="Enhanced self esteem - by gaining a deeper understanding of their own worth and capabilities, individuals can boost their self-confidence and embrace challenges with renewed confidence." />
            <ButterflyBulletPointRow gold label="Increased resilience - coaching equips individuals with the tools to navigate life's inevitable ups and downs, building resilience and adaptability." />
            <ButterflyBulletPointRow gold label="Improved performance - by setting clear goals and developing effective strategies, performance in both personal and professional life can be enhanced." />
            <ButterflyBulletPointRow gold label="Greater clarity and focus - through insightful questioning and reflective exercises, coaching shines a light on priorities and helps informed decision making." />
            <ButterflyBulletPointRow gold label="Reduced stress and anxiety - coaching provides a supportive environment to manage stress, reduce anxiety and build a sense of calm." />
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <h2 className="w-100 text-center my-2" style={{color: '#ffffff'}}>
              Give the gift of transformation
            </h2>
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'1024px'}}>
            <img
              src={certificate}
              style={{
                width: '100%',
                marginTop: '20px',
                filter: 'drop-shadow(0 0 20px #f7d490)'
              }}
              alt="Gift certificate example"
            />
          </div>
        </Col>

        <Col xs={12} className="p-4 d-flex justify-content-center">
          <div style={{maxWidth:'600px'}}>
            <p>
              This Christmas, choose a gift that truly makes a difference. By gifting a 'Revive' coaching package, you're investing in someone's happiness and success.
              It really is the gift that keeps on giving, empowering them to live a more fulfilling and purposeful life.
            </p>
            <p>
              As a special offer this season Cotswold Coaching Clinic are reducing the price of vouchers by £XX.
              This is only available here until 7pm on Christmas Eve.
            </p>
          </div>
        </Col>

        <Col xs={12} style={{marginBottom:'100px'}} className="text-center">
          <Button>
            Buy a Revive Coaching Package Gift Voucher<br />
            <span style={{textDecoration:'line-through'}}>£XXXX</span> £YYYY
          </Button>
        </Col>



      </Container>
    </React.Fragment>
  )
}

export default GroupCoaching;